<template>
  <div class="addLine">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加路线</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="formJbxx" :rules="jbxx" :model="formList" label-width="80px">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane name="1" label="基本信息">
          <el-form-item label="主标题" prop="travelName">
            <el-input v-model="formList.travelName" placeholder="请输入主标题"></el-input>
          </el-form-item>
          <el-form-item label="副标题" prop="secondName">
            <el-input v-model="formList.secondName" placeholder="请输入副标题"></el-input>
          </el-form-item>
          <el-form-item label="所属分类">
            <el-select
              @clear="onClear(2)"
              clearable
              v-model="cateValue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListA"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              @clear="onClear(2)"
              v-model="cateValue1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListB"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              @clear="onClear(2)"
              clearable
              v-model="formList.cateId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListC"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="label">
            <el-input
              v-model="formList.label"
              placeholder="请输入标签名,多个标签可用 , 隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否改期">
            <el-radio v-model="formList.changeInfo" :label="1">是</el-radio>
            <el-radio v-model="formList.changeInfo" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="出发城市" prop="city">
            <el-input v-model="formList.city" placeholder="请选择出发城市"></el-input>
          </el-form-item>
          <el-form-item label="销售平台">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group v-model="checked" @change="handleCheckedCitiesChange">
              <el-checkbox
                v-for="item in platform"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="供应商">
            <el-select v-model="formList.supplierId" placeholder="请选择">
              <el-option
                v-for="item in supplierOptions"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算价">
            <el-input
              v-model="formList.settlePrice"
              placeholder="请输入结算价"
            ></el-input>
          </el-form-item>
          <el-form-item label="游戏ID" prop="label">
            <el-input
              v-model="formList.gameId"
              placeholder="购票成功后是否参与游戏活动  0 为不参与"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐理由" prop="label">
            <el-input
              v-model="formList.recommend"
              placeholder="请输入推荐理由"
            ></el-input>
          </el-form-item>
          <el-form-item label="行程天数" class="col" id="cols" prop="days">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.days"
              placeholder="请输入行程天数"
            ></el-input>
            <el-form-item label="销量">
              <el-input-number
                v-model="formList.saleCount"
                :min="0"
                :step="1"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </el-form-item>
          <el-form-item label="销售方式" @click="formList = { ...formList }">
            <el-radio-group v-model="formList.sellWay">
              <el-radio v-model="formList.sellWay" :label="1">套餐</el-radio>
              <el-radio v-model="formList.sellWay" :label="2">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否享受会员权益">
            <el-radio v-model="formList.isUser" :label="1">是</el-radio>
            <el-radio v-model="formList.isUser" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-radio v-model="formList.isShow" :label="1">是</el-radio>
            <el-radio v-model="formList.isShow" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="万旅网是否显示">
            <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
            <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
          <el-form-item label="包含人数" class="form_box">
            <el-form-item label="成人数量" class="item">
              <el-input-number
                v-model="formList.adultCount"
                :min="0"
                :step="1"
                step-strictly
              ></el-input-number>
            </el-form-item>
            <el-form-item label="儿童数量" class="item">
              <el-input-number
                v-model="formList.childCount"
                :min="0"
                :step="1"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </el-form-item>
          <el-form-item label="购买数量" class="form_box">
            <el-form-item label="最少购买" class="item">
              <el-input-number
                v-model="formList.minPeople"
                :min="0"
                :step="1"
                step-strictly
              ></el-input-number>
            </el-form-item>
            <el-form-item label="最多购买" class="item">
              <el-input-number
                v-model="formList.maxPeople"
                :min="0"
                :step="1"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </el-form-item>
          <!-- <el-form-item label="评分" class="col" prop="score">
            <el-input
              v-model.trim="formList.score"
              @input="onInputChange"
              placeholder="请输入天数"
            ></el-input>
          </el-form-item> -->

          <div class="btn">
            <el-button type="primary" @click="onNext('2')">下一步</el-button>
            <el-button type="primary" @click="onSave" v-show="Number(id) !== 0"
              >保存</el-button
            >
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2" label="详情图片">
          <el-form-item label="封面图" prop="showImg">
            <send-image
              v-if="Number(id) === 0 || (Number(id) > 0 && formList.travelName !== '')"
              type="one"
              @addImageStr="(e) => addImageStr(e, 1)"
              :images="formList.showImg"
            ></send-image>
            <i>每张图片不能超过500K，建议尺寸：335*172px</i>
          </el-form-item>
          <el-form-item label="分享图">
            <send-image
              v-if="Number(id) === 0 || (Number(id) > 0 && formList.travelName !== '')"
              type="one"
              @addImageStr="(e) => addImageStr(e, 2)"
              :images="formList.shareImg"
            ></send-image>

            <i>每张图片不能超过500K，建议尺寸：500*400px</i>
          </el-form-item>
          <el-form-item label="轮播图" prop="travelImg">
            <send-image
              v-if="Number(id) === 0 || (Number(id) > 0 && formList.travelName !== '')"
              type="more"
              @addImageStrs="addImageStrs"
              :images="imgList.join(',')"
            ></send-image>

            <i>每张图片不能超过500K，建议尺寸：750*500px</i>
          </el-form-item>
          <el-form-item label="PC轮播图">
            <send-image
              type="morePc"
              v-if="Number(id) === 0 || (Number(id) > 0 && formList.travelName !== '')"
              :images="formList.pcImg.join(',')"
              :maxLength="4"
              @addImageStrsPc="addImageStrsPc"
            ></send-image>
            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：1960*680px</span
            >
          </el-form-item>
          <el-form-item label="产品特色">
            <VueUeditorWrap
              v-model="formList.details"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
            <!-- <quill-editor
              class="richEdit"
              :options="quillOption"
              v-model.trim="formList.details"
              style="height: 400px"
            >
            </quill-editor> -->
            <!-- <div
              v-for="item in detailsImgList"
              :key="item"
              class="img"
              v-show="detailsImgList.length"
            >
              <img :src="item" />
              <div class="el-icon-close" @click="onClose2(item)"></div>
            </div>
            <div class="addImg" @click="onSendImg2">
              <input
                type="file"
                hidden
                ref="fileRefs2"
                @change="onFileChange2"
              />
              <i class="el-icon-plus"></i>
            </div>
            <i>每张图片不能超过500K，建议尺寸：750 X 480px</i> -->
          </el-form-item>
          <el-form-item label="">
            <span style="color: red">建议尺寸750*1500px</span>
          </el-form-item>

          <div class="btn">
            <el-button type="primary" @click="onPre('1')">上一步</el-button>
            <el-button type="primary" @click="onNext('3')">下一步</el-button>
            <el-button type="primary" @click="onSave" v-show="Number(id) !== 0"
              >保存</el-button
            >
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3" label="退订规则">
          <!-- <el-form label-width="80px" :rules="tdgz" :model="formList" class="tdgz"> -->
          <el-form-item label="是否退订">
            <el-radio v-model="radiovalue" label="1">可以退订</el-radio>
            <el-radio v-model="radiovalue" label="0">不可退</el-radio>
          </el-form-item>
          <el-form-item label="退订规则" v-if="radiovalue == 1">
            <div>
              离游玩时间
              <el-input
                @input="onInputChange"
                v-model.number="formList.overTime3"
                placeholder=""
                style="width: 50%"
              ></el-input
              >小时免退订费
            </div>
            <br />
            <div>
              离游玩时间
              <el-input
                @input="onInputChange"
                v-model.number="formList.overTime1"
                placeholder=""
                style="width: 20%"
              ></el-input>
              小时以上,手续费
              <el-input
                @input="onInputChange"
                v-model.number="formList.charge1"
                placeholder=""
                style="width: 20%"
              ></el-input>
              %
            </div>
            <br />
            <div>
              离游玩时间
              <el-input
                @input="onInputChange"
                v-model.number="formList.overTime2"
                placeholder=""
                style="width: 20%"
              ></el-input>
              小时以内,手续费
              <el-input
                @input="onInputChange"
                v-model.number="formList.charge2"
                placeholder=""
                style="width: 20%"
              ></el-input>
              %
            </div>
          </el-form-item>
          <!-- </el-form> -->
          <div class="btn">
            <el-button type="primary" @click="onPre('2')">上一步</el-button>
            <el-button type="primary" @click="onNext('4')">下一步</el-button>
            <el-button type="primary" @click="onSave" v-show="Number(id) !== 0"
              >保存</el-button
            >
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane name="4" label="行程须知">
          <template>
            <div style="display: flex">
              <div style="width: 100px">行程安排</div>
              <VueUeditorWrap
                v-model="formList.scheduling"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">费用须知</div>
              <VueUeditorWrap
                v-model="formList.feeNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">退订说明</div>
              <VueUeditorWrap
                v-model="formList.backNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <!-- <div style="display: flex">
              <div style="width: 100px">费用不包括</div>
              <VueUeditorWrap
                v-model="formList.feeNo"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div> -->
          </template>

          <div class="btn">
            <el-button type="primary" @click="onPre('3')">上一步</el-button>
            <!-- <el-button type="primary" @click="onNext('5')">下一步</el-button> -->

            <el-button type="primary" @click="onSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane name="5" label="预订须知">
          <div style="display: flex">
            <div style="width: 100px">预订须知</div>
            <VueUeditorWrap
              v-model="formList.bookNotice"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </div>

          <div style="display: flex">
            <div style="width: 100px">特别说明</div>
            <VueUeditorWrap
              v-model="formList.specialNotice"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </div>
          <div style="display: flex">
            <div style="width: 100px">安全说明</div>
            <VueUeditorWrap
              v-model="formList.saveNotice"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </div>
          <div style="display: flex">
            <div style="width: 100px">温馨提示</div>
            <VueUeditorWrap
              v-model="formList.reminder"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </div>
          <div class="btn">
            <el-button type="primary" @click="onPre('4')">上一步</el-button>
            <el-button type="primary" @click="onSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
const platform = [
  { value: "1", name: "小程序" },
  { value: "2", name: "H5" },
  { value: "3", name: "IOS" },
  { value: "4", name: "Android" },
  { value: "5", name: "PC" },
];
import quillConfig from "../../../quill-config";
import { selectAllByFatherId } from "../../api/travel";
import { addLine, lineDetails } from "../../api/lineList";
import { sendFile } from "../../api/sendFile";
import sendImage from "../../components/sendImage.vue";
import { Quill } from "vue-quill-editor";
import { supList } from "../../api/supList.js";
import VueUeditorWrap from "vue-ueditor-wrap";
import { couponAllCateAPI } from "../../api/coupon";
import draggable from "vuedraggable";
export default {
  components: { sendImage, VueUeditorWrap, draggable },
  name: "addLine",
  props: {
    id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      imgList: [],
      detailsImgList: [],
      activeName: "1",
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      radiovalue: "1",
      jmsgRules: {},
      formList: {
        sellWay: 1,
        // travelId: this.id || 0,
        travelName: "",
        secondName: "",
        cateId: null,
        label: "",
        days: null,
        score: 4.9,
        showImg: "",
        travelImg: "",
        details: "",
        unsubscribe: 1,
        overTime1: "",
        overTime2: "",
        overTime3: "",
        charge1: "",
        charge2: "",
        scheduling: "",
        feeNotice: "",
        adultCount: "",
        childCount: "",
        minPeople: "",
        maxPeople: "",
        recommend: "",
        saleCount: null,
        backNotice: "",
        shareImg: "",
        gameId: "",
        settlePrice: "",
        supplierId: "",
        sellPlace: "",
        city: "",
        changeInfo: 1,
        pcImg: [],
        isShow: 1,
        feeNo: "",
        bookNotice: "",
        specialNotice: "",
        saveNotice: "",
        reminder: "",
        isUser: 0,
        wlShow: 1,
        isNew:0
      },
      jbxx: {
        travelName: [
          {
            required: true,
            message: "请输入主标题",
            trigger: "blur",
          },
        ],
        secondName: [
          {
            required: true,
            message: "请输入副标题",
            trigger: "blur",
          },
        ],
        label: [
          {
            required: true,
            message: "请输入标签",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "请输入城市",
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: "请输入行程天数",
            trigger: "blur",
          },
        ],
        score: [
          {
            required: true,
            message: "请输入评分",
            trigger: "blur",
          },
        ],
        hotelOverTime3: [
          {
            required: true,
            message: "请输入退订时间",
            trigger: "blur",
          },
        ],
        hotelOverTime1: [
          {
            required: true,
            message: "请输入退订时间",
            trigger: "blur",
          },
        ],
        hotelCharge1: [
          {
            required: true,
            message: "请输入退订费用",
            trigger: "blur",
          },
        ],
        showImg: [
          {
            required: true,
            message: "请上传封面图",
            trigger: "blur",
          },
        ],
        travelImg: [
          {
            required: true,
            message: "请上传轮播图",
            trigger: "blur",
          },
        ],

        hotelOverTime2: [
          {
            required: true,
            message: "请输入退订时间",
            trigger: "blur",
          },
        ],
        hotelCharge2: [
          {
            required: true,
            message: "请输入退订费用",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  watch: {
    cateValue: async function () {
      // this.cateValue1 = "";
      // this.formList.cateId = "";
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.cateListB = data.data;
    },
    cateValue1: async function () {
      if (!this.cateValue1) return;
      // this.formList.cateId = "";
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.cateListC = data.data;
    },
    imgList: function (newVal, oldVal) {
      console.log(newVal, "new", oldVal, "old");
      this.formList.travelImg = this.imgList.join(",");
    },
    detailsImgList: function () {
      this.formList.details = this.detailsImgList.join(",");
    },
    radiovalue: function () {
      this.formList.unsubscribe = Number(this.radiovalue);
    },
  },
  created() {
    this.getTravelCate();
    // this.getCate();
    this.getSupplierList();
    this.init();
    if (this.id != "0" || this.id != 0) {
      this.getLineDetails();
    }
  },
  methods: {
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    handleCheckAllChange(val) {
      this.checkVal = [];
      if (val) {
        this.init();
        console.log("if", this.checkVal);
        this.checked = this.checkVal;
        this.formList.sellPlace = "0";
      } else {
        this.checked = [];
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.platform.length;
      this.formList.sellPlace = this.checked.toString();
      console.log(value, "value");
    },
    init() {
      for (let i = 0; i < this.platform.length; i++) {
        this.checkVal.push(this.platform[i].value);
      }
    },
    async getCate() {
      const { data } = await couponAllCateAPI();
      let arr = data.data;
      arr.map((e) => {
        this.supplierOptions.push({ supplierId: e.cateId, label: e.cateName });
      });
    },
    //获得二级分类
    async getcateListB() {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.cateListB = data.data;
    },
    async getcateListC() {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.cateListC = data.data;
    },
    async getLineDetails() {
      const { data } = await lineDetails({
        travelId: Number(this.id),
      });
      Object.keys(this.formList).forEach((key) => {
        this.formList[key] = data.data[0][key];
      });
      // this.formList.travelName = data.data[0].travelName;
      this.formList.secondName = data.data[0].travelSecondName || "";

      // this.formList.cateId = data.data[0].cateId;
      // // console.log(this.cateValue1, this.formList.cateId, "赋值");
      // this.formList.label = data.data[0].label;
      // this.formList.days = data.data[0].days;
      // this.formList.score = data.data[0].score;
      // this.formList.showImg = data.data[0].showImg;

      // this.formList.details = data.data[0].details;

      // this.formList.overTime3 = data.data[0].overTime3;
      // this.formList.overTime1 = data.data[0].overTime1;
      // this.formList.charge1 = data.data[0].charge1;
      // this.formList.overTime2 = data.data[0].overTime2;
      // this.formList.charge2 = data.data[0].charge2;
      // this.formList.scheduling = data.data[0].scheduling;
      // this.formList.feeNotice = data.data[0].feeNotice;
      // this.formList.backNotice = data.data[0].backNotice;
      // this.formList.sellWay = data.data[0].sellWay;
      // this.formList.adultCount = data.data[0].adultCount;
      // this.formList.childCount = data.data[0].childCount;
      // this.formList.minPeople = data.data[0].minPeople;
      // this.formList.maxPeople = data.data[0].maxPeople;
      // this.formList.recommend = data.data[0].recommend;
      // this.formList.saleCount = data.data[0].saleCount;
      // this.formList.shareImg = data.data[0].shareImg;
      // this.formList.gameId = data.data[0].gameId;
      // this.formList.sellPlace = data.data[0].sellPlace;
      // this.formList.supplierId = data.data[0].supplierId;
      // this.formList.settlePrice = data.data[0].settlePrice;
      // this.formList.city = data.data[0].city;
      // this.formList.changeInfo = data.data[0].changeInfo;
      this.formList.pcImg = data.data[0].pcImg || [];
      this.formList.isUser = data.data[0].isUser || 0;
      this.cateValue = data.data[0].firstCateId;
      this.cateValue1 = data.data[0].secondId;
      this.radiovalue = data.data[0].unsubscribe + "";
      this.detailsImgList = data.data[0].details.split(",");
      this.imgList = data.data[0].travelImg;
      console.log(this.checked, "this.checked");

      if (data.data[0].sellPlace == "0") {
        this.checked = this.checkVal;
        this.checkAll = true;
      } else {
        this.checked = data.data[0].sellPlace.split(",");
      }

      this.getcateListB();
      this.getcateListC();
    },
    async getTravelCate() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      console.log(data);
      this.cateListA = data.data;
    },
    addImageStr(image, type) {
      if (type === 1) {
        this.formList.showImg = image;
      } else if (type === 2) {
        this.formList.shareImg = image;
      }
    },
    addImageStrs(image) {
      this.imgList = image;
    },
    addImageStrsPc(image) {
      this.formList.pcImg = image;
    },
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.formList.showImg = data.data[0].src;
      }
    },
    async onFileChange1(event) {
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        if (this.imgList.length < 6) {
          this.imgList.push(data.data[0].src);
        } else {
          this.$message({
            showClose: true,
            message: "最多只能添加6张图片哦",
            type: "warning",
          });
          return;
        }
      }
    },
    async onFileChange2(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        // this.formList.details = data.data[0].src;
        if (this.detailsImgList.length < 100) {
          this.detailsImgList.push(data.data[0].src);
        } else {
          this.$message({
            showClose: true,
            message: "最多只能添加100张图片哦",
            type: "warning",
          });
          return;
        }
      }
    },

    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    onSendImg2() {
      this.$refs.fileRefs2.click();
    },
    onClose() {
      this.formList.showImg = "";
    },
    onClose1(item) {
      this.imgList = this.imgList.filter((value) => {
        return value != item;
      });
    },
    onClose2(item) {
      // this.formList.details = "";
      this.detailsImgList = this.detailsImgList.filter((value) => {
        return value != item;
      });
    },
    onInputChange(value) {
      if (isNaN(value)) {
        this.$message({
          showClose: true,
          message: "请输入数字！",
          type: "warning",
        });
      }
    },
    onSave() {
      // let keyList = { ...this.formList };
      // if (this.radiovalue == 0) {
      //   delete keyList.overTime1;
      //   delete keyList.overTime2;
      //   delete keyList.overTime3;
      //   delete keyList.charge1;
      //   delete keyList.charge2;
      // }
      // for (const key in keyList) {
      //   if (this.formList[key] === null || this.formList[key] === "") {
      //     console.log(key, this.formList[key]);
      //     this.$message({
      //       showClose: true,
      //       message: "请把数据填写完整",
      //       type: "warning",
      //     });
      //     return;
      //   }
      // }
      // if (!this.formList.cateId) {
      //   this.$message({
      //     showClose: true,
      //     message: "请填写完整分类",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$refs.formJbxx.validate(async (validate) => {
        if (!validate) {
          return;
        }
        // return;
        if (this.load) return;
        const params = {
          ...this.formList,
          pcImg: this.formList.pcImg.join(","),
          travelId: this.id,
        };
        this.load = true;

        const { data } = await addLine(params);
        this.load = false;
        console.log(data);
        if (data.code == 0) {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
          this.$router.go(-1);
        } else {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "error",
          });
        }
      });

      // this.formList.pcImg = this.formList.pcImg.join(",");
    },
    onNext(id) {
      this.activeName = id;
    },
    onPre(id) {
      this.activeName = id;
    },
    onClear(id) {
      if (id == 1) {
        this.list.status = null;
      } else {
        this.formList.cateId = null;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addLine {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .el-tabs {
    margin-top: 50px;

    .btn {
      width: 400px;
      margin-left: 400px;
    }
  }

  /deep/.el-form {
    #cols {
      .el-form-item__content {
        flex-wrap: unset !important;
      }
    }
    .col {
      display: inline-block;

      .el-form-item__content {
        .el-input {
          width: 100%;
        }
      }
    }

    /deep/.el-form-item {
      .el-form-item__content {
        .el-select {
          width: 270px;

          .el-input {
            width: 265px;
            max-width: 90%;
          }
        }

        .el-radio {
          margin: 10px 0 0 20px;
        }

        display: flex !important;
        flex-wrap: wrap !important;

        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          margin-top: 10px;

          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }

          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }

        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 10px;

          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }

        i {
          // margin-left: 20px;
          color: red;
        }

        .el-input {
          max-width: 800px;
        }
      }
    }
  }

  /deep/.tdgz {
    .el-form-item {
      .el-form-item__content {
        display: block !important;

        div {
          margin-top: 20px;

          .el-input {
            display: inline-block;
            max-width: 200px;
          }
        }
      }
    }
  }

  /deep/.quill-editor {
    height: 400px;

    .ql-container {
      height: 350px;
    }
  }

  .form_box {
    display: flex;

    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }

    .item {
      flex: 1;

      &:last-child {
        margin-left: 30px;
      }

      /deep/.item_inp {
        width: 250px !important;
      }
    }
  }
}
</style>
